import scoreConfigs from "lib/scoreBoard/scoreConfigs";
import { useMemo } from "react";
import { Geo } from "types/MapContext";
import { CombinedDatapoints } from "types/cube";
import {
  AllExploratoryOption,
  ExploratoryType,
  allExploratoryOptions,
  exploratoryGroupMap,
  freeExploratories,
  popularExploratories,
} from "../lib/options/exploratory";

export interface ExploratoryOption extends AllExploratoryOption {
  premium?: boolean;
}

export interface GroupedExploratoryOption {
  label: string;
  options: ExploratoryOption[];
}

interface IUseGeoExploratory {
  freeExploratories: CombinedDatapoints[];
  popularExploratories: CombinedDatapoints[];
  exploratoryOptions: ExploratoryOption[];
  groupedExploratories: GroupedExploratoryOption[];
  groupedExploratoriesWithScores: GroupedExploratoryOption[];
  groupedExploratoriesWithScoreData: GroupedExploratoryOption[];
  groupedExploratoriesWithPopular: GroupedExploratoryOption[];
  geoSpecificFilter(geo?: Geo): ExploratoryOption[];
  geoSpecificFilterValues(): string[];
}

const useGeoExploratory = (geo?: Geo): IUseGeoExploratory => {
  const {
    popular,
    demographic,
    homePriceAndAffordability,
    investorMetrics,
    marketTrend,
    scores,
    bls,
  } = exploratoryGroupMap;

  const geoSpecificFilter = (geo?: Geo) => {
    if (!geo) return allExploratoryOptions;

    return allExploratoryOptions.filter((option) => option.geo.includes(geo));
  };

  const geoSpecificFilterValues = () => {
    return geoSpecificFilter(geo).map((filter) => filter.value);
  };

  const exploratoryOptions: ExploratoryOption[] = geoSpecificFilter(geo)
    .map((exploratory) => ({
      ...exploratory,
      premium:
        !freeExploratories.includes(exploratory.value) ||
        exploratory.type === ExploratoryType.SCORE,
    }))
    .sort((a, b) => Number(a.premium) - Number(b.premium));

  const [
    groupedExploratories,
    groupedExploratoriesWithPopular,
    groupedExploratoriesWithScores,
    groupedExploratoriesWithScoreData,
  ] = useMemo(() => {
    const withoutPopular = [
      {
        label: homePriceAndAffordability,
        options: exploratoryOptions.filter(
          (option) => option.category === homePriceAndAffordability,
        ),
      },
      {
        label: marketTrend,
        options: exploratoryOptions.filter(
          (option) => option.category === marketTrend,
        ),
      },
      {
        label: demographic,
        options: exploratoryOptions.filter(
          (option) => option.category === demographic,
        ),
      },
      {
        label: investorMetrics,
        options: exploratoryOptions.filter(
          (option) => option.category === investorMetrics,
        ),
      },
      {
        label: bls,
        options: exploratoryOptions.filter((option) => option.category === bls),
      },
    ];
    const withPopular = [
      {
        label: popular,
        options: exploratoryOptions.filter((option) => !option.premium),
      },
      ...withoutPopular,
    ];
    const withScores = [
      {
        label: scores,
        options: exploratoryOptions.filter(
          (option) => option.category === scores && !option.isComingSoon,
        ),
      },
      ...withoutPopular,
    ];
    const withScoreData = scoreConfigs.reduce(
      (acc, c) => {
        if (!geo || (c.geo.includes(geo) && !c.isComingSoon)) {
          acc.push({
            label: `${c.label} (Scores)`,
            options: [
              ...(c.extraData?.map((e) => ({
                premium: e.premium,
                label: `${e.label} Score`,
                value: e.code,
                category: c.label,
                isComingSoon: e.isComingSoon,
                ytVideoCode: "",
                geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
                type: ExploratoryType.SCORE,
              })) || []),
            ],
          });
        }

        return acc;
      },
      [...withScores],
    );

    return [withoutPopular, withPopular, withScores, withScoreData];
  }, [geo]);

  return {
    freeExploratories,
    exploratoryOptions,
    groupedExploratories,
    popularExploratories,
    groupedExploratoriesWithScores,
    groupedExploratoriesWithPopular,
    groupedExploratoriesWithScoreData,
    geoSpecificFilter,
    geoSpecificFilterValues,
  };
};

export default useGeoExploratory;
