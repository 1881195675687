// Dependencies
import { Flex } from "components/Flex";
import useFeedbackPopUp from "hooks/useFeedbackPopUp";
import Image from "next/image";
import { useRouter } from "next/router";
import ChatIcon from "public/chat-icon.svg";

export default function ProfileFeedbackButton(): JSX.Element {
  const router = useRouter();
  const { showFeedbackPopup } = useFeedbackPopUp();

  return router.asPath.startsWith("/profile") ? (
    <>
      <Flex
        align="center"
        justify="center"
        css={(theme) => ({
          position: "fixed",
          bottom: 30,
          right: 8,
          height: 58,
          width: 58,
          borderRadius: 37,
          backgroundColor: theme.colors.white,
          zIndex: 100,
          cursor: "pointer",
          boxShadow: "0px 4px 51px 0px rgba(0, 0, 0, 0.16)",

          ":hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
          },
        })}
        onClick={() => showFeedbackPopup()}
      >
        <Image
          src={ChatIcon}
          alt="Feedback"
          style={{ width: 30, height: 30 }}
        />
      </Flex>
    </>
  ) : (
    <></>
  );
}
