import { User } from "backend/types/user.type";
import { Flex } from "components/Flex";
import RoundedCrossIcon from "components/icons/RoundedCrossIcon";
import { doc, updateDoc } from "firebase/firestore";
import { useUserData } from "hooks/useUser";
import { createEngageUserObject } from "lib/helpers/userHelpers";
import { countyMetroMap } from "lib/options/countyMetroMap";
import { metroList } from "lib/options/metroList";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { MetroOption } from "types/options";
import { AuthModalContext } from "../../lib/context";
import { db } from "../../lib/firebase";
import { Button } from "../Button";
import { Text } from "../Text";
import { SelectInput } from "../common/Select";

const whoAreYouOptions = ["Homebuyer", "Investor", "Realtor", "Other"];

const customFilterModalStyles = {
  overlay: {
    zIndex: 1040,
  },
  content: {
    zIndex: 1050,
    maxWidth: "800px",
    width: "100%",
    margin: "0 auto",
    background: "transparent",
    minHeight: "100%",
    padding: 0,
    display: "flex",
    alignItems: "center",
    border: "none",
  } as React.CSSProperties,
};

const tellUsMoreDelayTimer = 3 * 60 * 1000;

export const TellUsMore = () => {
  const { showTellUsMore: showModal, setShowTellUsMore: setShowModal } =
    useContext(AuthModalContext);
  const [whoAreYou, setWhoAreYou] = useState({ label: "", value: "" });
  const [whereAreYouLooking, setWhereAreYouLooking] = useState<MetroOption>({
    label: "",
    value: -1,
    "FIPS County Code": "",
    "FIPS State Code": "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const posthog = usePostHog();
  const user = useUserData();

  //   useEffect(() => {
  //     if (
  //       router.pathname === "/dashboard" &&
  //       user?.id &&
  //       (!user?.defaultMetro?.value || !user?.whoAreYou)
  //     ) {
  //       const timer = setTimeout(() => {
  //         setShowModal(true);
  //       }, tellUsMoreDelayTimer);
  //       return () => clearTimeout(timer);
  //     }
  //   }, [user?.id, user?.defaultMetro?.value, user?.whoAreYou, router.pathname]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setError("Please select all fields!");
      return;
    }
    try {
      e.preventDefault();
      if (!validateForm()) {
        setError("Please select all fields!");
        return;
      }
      setError("");
      setLoading(true);
      await updateDoc(doc(db, "users", user.uid as string), {
        whoAreYou: whoAreYou?.value,
        defaultMetro: whereAreYouLooking,
      });
      const countyStateMetroMap = countyMetroMap.find(
        (item) =>
          item.cbsacode ===
          (whereAreYouLooking?.value || user?.defaultMetro?.value),
      );
      const state = countyStateMetroMap?.statename;

      const userAttributes = createEngageUserObject({
        user: user as User,
        overwrite: {
          state,
          metro: whereAreYouLooking?.label || user?.defaultMetro?.label,
          role: whoAreYou.value || user.whoAreYou,
          newState: "-",
        },
      });

      Engage.identify({
        id: user.email,
        email: user.email,
        ...(user?.name ? { firstName: user?.name } : {}),
      });

      Engage.addAttribute(user.email, userAttributes);
      posthog.identify(user.email, userAttributes);

      delete router.query["showTellUsMore"];
      let relativeURL = "";

      const slug = router.query.slug;
      if (slug) {
        relativeURL = router.pathname.replace("[slug]", slug as string);
      } else {
        relativeURL = router.pathname;
      }
      router.replace(
        { pathname: relativeURL, query: router.query },
        undefined,
        {
          shallow: true,
        },
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  const validateForm = () => {
    return !!whoAreYou?.value && whereAreYouLooking?.value !== -1;
  };

  const resetInitialState = () => {
    setWhereAreYouLooking({
      label: "",
      value: -1,
      "FIPS County Code": "",
      "FIPS State Code": "",
    });
    setWhoAreYou({
      label: "",
      value: "",
    });
    setError("");
    setLoading(false);
  };

  useEffect(() => {
    if (!showModal) {
      resetInitialState();
    }
  }, [showModal]);

  return (
    //@ts-ignore
    <Modal
      isOpen={showModal && router.pathname === "/dashboard"}
      onRequestClose={() => {
        setShowModal(false);
      }}
      style={customFilterModalStyles}
      ariaHideApp={false}
    >
      <main
        css={(theme) => ({
          position: "relative",
          width: "90%",
          maxWidth: "374px",
          borderRadius: theme.radii.small,
          margin: "50px auto",
          boxShadow: theme.shadow,
          backgroundColor: theme.colors.white,
          padding: theme.padding.large,
        })}
      >
        <h1
          css={(theme) => ({
            paddingTop: "0px",
            paddingRight: theme.padding.medium,
            fontSize: 30,
            margin: "0px 0px 5px 0px",
          })}
        >
          <span>Tell Us More</span>
        </h1>

        <Text>
          Help us improve the Reventure App by letting us know which area you
          are looking to buy and what your interest/role in real estate is
        </Text>
        <SelectInput
          placeholder="Where are you looking to Buy?"
          options={(metroList as any).concat([
            { value: "other", label: "Other" },
          ])}
          isClearAble={false}
          onChange={(val) => setWhereAreYouLooking(val as MetroOption)}
          controlStyle={{ marginTop: 30 }}
        />

        <SelectInput
          options={whoAreYouOptions.map((option) => ({
            value: option,
            label: option,
          }))}
          controlStyle={{ marginTop: 30 }}
          isClearAble={false}
          placeholder="What's your role in Real Estate?"
          onChange={(val) =>
            setWhoAreYou(val as { value: string; label: string })
          }
        />

        {error && (
          <Text
            css={(theme) => ({
              color: theme.colors.red,
              textAlign: "center",
              marginTop: 8,
            })}
          >
            {error}
          </Text>
        )}
        <Button
          css={() => ({
            marginTop: "26px",
            marginBottom: 10,
            width: "100%",
          })}
          variant="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          Continue
        </Button>
      </main>
    </Modal>
  );
};
