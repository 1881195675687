import { Datapoints } from "./cube";

export enum ExploratorySources {
  ZILLOW = "Zillow",
  ACS = "US Census Bureau",
  REVENTURE = "Reventure App Calculation",
  FRED = "FRED",
  REALTOR = "Realtor.com",
  ACS_MIGRATION = "US Census Bureau (Migration)",
  BLS = "Bureau of Labor Statistics",
}

export interface ExploratoryMapObject {
  code: Datapoints;
  name: string;
  filterName: string;
  range?: string;
  sources: ExploratorySources[];
  description?: string;
  summary: string;
  shortSummary?: string;
  calculated: boolean;
  censusCodes?: string[];
}

export interface ExploratoryMap {
  [key: string]: ExploratoryMapObject;
}
