import * as Sentry from "@sentry/react";
import { Analytics } from "@vercel/analytics/react";
import ProfileFeedbackButton from "components/Feedback/ProfileFeedbackButton";
import WelcomePopUp from "components/auth/WelcomePopUp";
import { CancelSubscription } from "components/billings/CancelSubscription";
import { CancelSubscriptionFeedback } from "components/billings/CancelSubscriptionFeedback";
import { DowngradeConfirmation } from "components/billings/DowngradeConfirmationModal";
import { DowngradeSuccessfulModal } from "components/billings/DowngradeSuccessfulModal";
import { UpgradePlanModal } from "components/billings/UpgradePlanModal";
import PaymentModal from "components/plan/PaymentModal";
import PlanModal from "components/plan/PlanModal";
import { Provider } from "components/provider";
import theme from "lib/theme";
import localForage from "localforage";
import Head from "next/head";
import { useRouter } from "next/router";
import { GoogleAnalytics } from "nextjs-google-analytics";
import NextNProgress from "nextjs-progressbar";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { AuthModal } from "../components/auth/AuthModal";
import { TellUsMore } from "../components/auth/TellUsMore";
import { redirect } from "next/navigation";

// Styles
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "antd/dist/antd.css";
import "antd/dist/antd.variable.min.css";
import { CheaperSubscription } from "components/billings/CheaperSubscription";
import { DowngradingModal } from "components/billings/DowngradingModal";
import "rc-slider/assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import "../styles/globals.css";
import { RenewPlanModal } from "components/billings/RenewPlanModal";

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_SECRET as string, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug(false);
    },
  });

  //@ts-ignore
  !(function (n) {
    if (!window.Engage) {
      (window[n] = window[n] || {}),
        (window[n].queue = window[n].queue || []),
        (window.Engage = window.Engage || {});
      for (
        var e = ["init", "identify", "addAttribute", "track"], i = 0;
        i < e.length;
        i++
      )
        window.Engage[e[i]] = w(e[i]);
      var d = document.createElement("script");
      (d.src = "//d2969mkc0xw38n.cloudfront.net/next/engage.min.js"),
        (d.async = !0),
        document.head.appendChild(d);
    }
    function w(e) {
      return function () {
        window[n].queue.push([e].concat([].slice.call(arguments)));
      };
    }
  })("engage");

  Engage.init({
    key: process.env.NEXT_PUBLIC_ENGAGE_API_KEY,
    secret: process.env.NEXT_PUBLIC_ENGAGE_SECRET_KEY,
    socketOptions: {
      pollingInterval: 15000, // 15 seconds
      transports: ["websocket", "polling"],
    },
  });
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_IS_MAINTENANCE_ACTIVE === "true") {
      router.push("/maintenance");
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = () => posthog.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    localForage.clear();
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  Sentry.addGlobalEventProcessor((event) => {
    if (
      event?.exception &&
      event?.exception?.values &&
      event?.exception?.values[0]
    ) {
      const errorValue = event.exception.values[0];
      if (errorValue.type === "Error" && errorValue.stacktrace) {
        const frames = errorValue.stacktrace.frames;
        console.log("[ERROR]- Sentry Logs ->", event);
        if (frames && frames.length > 0) {
          const culpritFrame = frames[0];
          event.extra = {
            ...event.extra,
            culpritFrame: {
              filename: culpritFrame.filename,
              function: culpritFrame.function,
              lineno: culpritFrame.lineno,
              colno: culpritFrame.colno,
            },
          };
        }
      }
    }
    return event;
  });

  return (
    <>
      <PostHogProvider client={posthog}>
        <Head>
          <title>Reventure App</title>
          <meta
            name="viewport"
            content="width=device-width, user-scalable=no"
          />
        </Head>
        <GoogleAnalytics gaMeasurementId={process.env.NEXT_PUBLIC_GA_ID} />
        <Provider>
          <NextNProgress color={theme.colors.primary} />
          <Component {...pageProps} />
          <ToastContainer />
          <Analytics />
          <AuthModal />
          <TellUsMore />
          <PlanModal />
          <PaymentModal />
          <UpgradePlanModal />
          <DowngradeConfirmation />
          <DowngradingModal />
          <RenewPlanModal />
          <DowngradeSuccessfulModal />
          <CancelSubscription />
          <CheaperSubscription />
          <CancelSubscriptionFeedback />
          <WelcomePopUp />
          {router.pathname === "/profile/help" ? "" : <ProfileFeedbackButton />}
        </Provider>
      </PostHogProvider>
    </>
  );
}

export default MyApp;
