import { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { auth } from "../../lib/firebase";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useUserData } from "hooks/useUser";
import useWelcomePopUp from "hooks/useWelcomePopUp";
import { AuthModalContext } from "lib/context";
import { useRouter } from "next/router";
import React from "react";
import {
  authEvent,
  getAuthError,
  identifyUser,
  signInWithGoogle,
  getUser,
  createUser,
  updateLoginCount,
} from "../../lib/auth";
import { AuthForm } from "../AuthForm";
import { User as FirebaseUser } from "firebase/auth";
import { updateUser } from "lib/helpers/userHelpers";
import useGeoSearch from "hooks/useGeoSearch";

const customFilterModalStyles = {
  overlay: {
    zIndex: 1100,
  },
  content: {
    zIndex: 1050,
    maxWidth: "800px",
    width: "100%",
    margin: "0 auto",
    background: "transparent",
    minHeight: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    padding: 0,
    border: "none",
  } as React.CSSProperties,
};

export const AuthModal = ({ canBeClosed = false }) => {
  const { geo } = useGeoSearch();
  const router = useRouter();
  const { showAuthModal, setShowAuthModal, authType, setAuthType } =
    useContext(AuthModalContext);
  const { setShowWelcomePopup } = useWelcomePopUp();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullname, setFullname] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const user = useUserData();

  const resetInitialState = () => {
    setPassword("");
    setEmail("");
    setFullname("");
    setError("");
    setConfirmPassword("");
    setLoading(false);
    setAcceptTerms(false);
  };

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userInDb = await getUser(user);
        if (!userInDb) {
          let name = "";
          setFullname((n) => {
            name = n;
            return "";
          });
          createUser(user, user.displayName || name).then(() => {
            setShowWelcomePopup(true);
          });
          identifyUser({ ...user, name: user.displayName || name });
          authEvent({
            provider:
              user.providerData[0].providerId === "password"
                ? "email"
                : "google",
            user: user,
            authType: "Sign Up",
          });
        } else {
          if (!userInDb.name && !!user.displayName) {
            updateUser(user.uid, { name: user.displayName });
          }
        }
      }
    });

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    if (showAuthModal) {
      resetInitialState();
    }
  }, [showAuthModal, authType]);

  const handleSignInEvents = async (
    authUser: FirebaseUser,
    provider: "google" | "email",
  ) => {
    const userInDb = await getUser(authUser);
    if (userInDb) {
      authEvent({
        provider,
        user: authUser,
        authType: "Sign In",
      });
      updateLoginCount(authUser);
      identifyUser(userInDb);
    }
  };

  const handleGoogleSignIn = async () => {
    setError("");
    try {
      const currentUser = await signInWithGoogle();
      handleSignInEvents(currentUser, "google");
    } catch (error) {
      setError(getAuthError(error));
    } finally {
      setLoading(false);
    }
  };

  const loginWithEmailAndPassword = () => {
    if (!email || !password) {
      setError("Please enter required fields");
      return;
    }
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        handleSignInEvents(user, "email");
      })
      .catch((error) => {
        setError(getAuthError(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const signUpWithEmailAndPassword = () => {
    if (!acceptTerms) {
      return setShowTermsError(true);
    }
    if (!email || !password || !fullname || !confirmPassword) {
      setError("Please enter required fields");
      return;
    }
    if (password !== confirmPassword) {
      setError("Confirm password does not match");
      return;
    }
    setLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        setError(getAuthError(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    //@ts-ignore
    <Modal
      isOpen={showAuthModal && !user?.uid}
      onRequestClose={() => {
        if (canBeClosed) {
          setShowAuthModal(false);
        }
      }}
      style={customFilterModalStyles}
      ariaHideApp={false}
    >
      <AuthForm
        acceptTerms={acceptTerms}
        setAcceptTerms={setAcceptTerms}
        setFullname={setFullname}
        fullname={fullname}
        authType={authType}
        setAuthType={setAuthType}
        loading={loading}
        error={error}
        setError={setError}
        email={email}
        password={password}
        setEmail={setEmail}
        setPassword={setPassword}
        setShowTermsError={setShowTermsError}
        showTermsError={showTermsError}
        onFormSubmit={
          authType === "signUp"
            ? signUpWithEmailAndPassword
            : loginWithEmailAndPassword
        }
        onGoogleSubmit={handleGoogleSignIn}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
      />
    </Modal>
  );
};
