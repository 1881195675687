import { UilArrowGrowth, UilChartDown } from "@iconscout/react-unicons";
import scoreDatapointsMap from "lib/scoreBoard/scoreDatapointsMap";
import { isNumber } from "lodash";
import { ScoreDatapoints } from "types/cube";

export function getNameForScoreCode(code: ScoreDatapoints | string) {
  if (
    scoreDatapointsMap[code]?.parent?.includes(
      ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE as any,
    )
  ) {
    return `${scoreDatapointsMap[code]?.label} Score` || "";
  }
  return scoreDatapointsMap[code]?.label || "";
}

export function getSummaryForScoreCode(code: ScoreDatapoints | string) {
  return scoreDatapointsMap[code]?.summary || "";
}

export function getShortSummaryForScoreCode(code: ScoreDatapoints | string) {
  return scoreDatapointsMap[code]?.shortSummary || "";
}

export const getLegend = (value: number, type: ScoreDatapoints) => {
  if (!type) return;

  const legends = {
    [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE]: [
      { text: "Price Down", Icon: UilChartDown, color: "error" },
      { text: "Price Stable", Icon: null, color: "lightBlue" },
      { text: "Price Up", Icon: UilArrowGrowth, color: "brightGreen" },
    ],
    [ScoreDatapoints.HOME_BUYER_SCORE]: [
      { text: "Poor", Icon: UilChartDown, color: "error" },
      { text: "Below Average", Icon: UilChartDown, color: "error" },
      { text: "Average", Icon: null, color: "lightBlue" },
      { text: "Above Average", Icon: UilArrowGrowth, color: "lightBlue" },
      { text: "Great", Icon: UilArrowGrowth, color: "brightGreen" },
    ],
    [ScoreDatapoints.INVESTOR_SCORE]: [
      { text: "Poor", Icon: UilChartDown, color: "error" },
      { text: "Below Average", Icon: UilChartDown, color: "error" },
      { text: "Average", Icon: null, color: "lightBlue" },
      { text: "Above Average", Icon: UilArrowGrowth, color: "lightBlue" },
      { text: "Great", Icon: UilArrowGrowth, color: "brightGreen" },
    ],
  };

  const index = getScoreRangeLevel(value, type);
  if (!isNumber(index)) return "";

  return legends[type][index] || {};
};

export const getScoreRangeLevel = (score, type: ScoreDatapoints) => {
  return {
    [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE]:
      score < 0.45 ? 0 : score < 0.56 ? 1 : 2,
    [ScoreDatapoints.HOME_BUYER_SCORE]:
      score < 0.2 ? 0 : score < 0.4 ? 1 : score < 0.6 ? 2 : score < 0.8 ? 3 : 4,
    [ScoreDatapoints.INVESTOR_SCORE]: score < 0.45 ? 0 : score < 0.56 ? 1 : 2,
  }[type];
};
