import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocalStorage } from "react-use";
import { auth } from "../lib/firebase";
import { useStopWatch } from "./useStopWatch";

export const useAuthModal = () => {
  const router = useRouter();
  const [userAuth, isAuthLoading] = useAuthState(auth);
  const {
    timer: stopWatchTimer,
    resetTimer,
    startWatchTimer,
  } = useStopWatch("stopwatchTime");
  const [hasVisitedWithoutLogin, setHasVisitedWithoutLogin] = useLocalStorage(
    "hasVisitedWithoutLogin",
    false,
  );

  const [authType, setAuthType] = useState("signUp");
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showTellUsMore, setShowTellUsMore] = useState(false);

  useEffect(() => {
    const isBlacklisted = router.pathname.startsWith("/content");
    const isBlogsPage = router.pathname.startsWith("/blog");
    const isMaintenancePage = router.pathname.startsWith("/maintenance");
    let timer;

    if (
      !auth.currentUser &&
      !isAuthLoading &&
      !isBlacklisted &&
      !isBlogsPage &&
      !isMaintenancePage
    ) {
      if (!hasVisitedWithoutLogin) {
        startWatchTimer();
        if (stopWatchTimer >= 240000) {
          setHasVisitedWithoutLogin(true);
          setShowAuthModal(true);
          resetTimer();
        }
      } else {
        timer = setTimeout(() => {
          setShowAuthModal(true);
        }, 1000 * 120);
      }
    } else {
      setShowAuthModal(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isAuthLoading, stopWatchTimer, router.pathname]);

  useEffect(() => {
    if (userAuth?.uid) {
      setShowAuthModal(false);
    }
  }, [userAuth]);

  useEffect(() => {
    if (!userAuth?.uid && !isAuthLoading) {
      if (router.query?.type === "login") {
        setAuthType("login");
        setShowAuthModal(true);
      }
      if (router.query?.type === "signup") {
        setAuthType("signUp");
        setShowAuthModal(true);
      }
    } else if (userAuth?.uid && router.query.type) {
      delete router.query.type;
      router.replace(
        { pathname: router.pathname, query: router.query },
        undefined,
        { shallow: true },
      );
    }
  }, [router.query.type, userAuth?.uid, isAuthLoading]);

  return {
    authType,
    setShowAuthModal,
    setAuthType,
    showAuthModal,
    setShowTellUsMore,
    showTellUsMore,
  };
};
