import { PlanModalContext } from "components/provider/PlanModalProvider";
import { checkNumberValidity, cloneObject } from "lib/helpers/common";
import { getCodeForLabel } from "lib/helpers/exploratoryHelpers";
import {
  filterOutliers,
  isFilterExcludedFromGeo,
} from "lib/helpers/filterHelpers";
import { hasAccessToPremiumArea } from "lib/helpers/userHelpers";
import { allExploratoryOptions } from "lib/options/exploratory";
import { exploratoryMap } from "lib/options/exploratoryMap";
import { filterPresets as filterPresetsOptions } from "lib/options/filterPresets";
import { maxBy, minBy } from "lodash";
import { usePostHog } from "posthog-js/react";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { useLocalStorage } from "react-use";
import { FeatureState } from "types/Mapbox";
import { CurrentDataPoints, Datapoints } from "types/cube";
import { IFilter, IFilterPreset } from "types/options";
import useGeoSearch from "./useGeoSearch";
import { useUserData } from "./useUser";

interface FilterStates {
  [key: string]: { minVal: number; maxVal: number };
}

interface FilterContext {
  filterList;
  filterPresets: IFilterPreset[];
  selectedPreset: string;
  selectedFilters: IFilter[];
  setFilterList;
  exploratory: Datapoints;
  graphQuery: boolean;
  setGraphQuery: Dispatch<SetStateAction<boolean>>;
  setExploratory: Dispatch<SetStateAction<Datapoints>>;
  handleExploratoryChange: (val: Datapoints, row?: any) => boolean;
  setSelectedPreset: Dispatch<SetStateAction<string>>;
  setSelectedFilters: Dispatch<SetStateAction<IFilter[]>>;
  setFilterPresets: Dispatch<SetStateAction<IFilterPreset[]>>;
  setFiltersFromPreset(preset: IFilterPreset): void;
  updatePresets(datapointValues: CurrentDataPoints[]): IFilterPreset[];
  resetFilters(): IFilter[];
  fetchFilterStates(
    filters: Partial<IFilter>[],
    datapointValues: CurrentDataPoints[],
  ): FilterStates;
}

const FilterContext = createContext<FilterContext | undefined>(undefined);

export const FiltersProvider = ({ children }) => {
  const { setShowPlanModal, setPlanPopupTrigger } =
    useContext(PlanModalContext);
  const { geo } = useGeoSearch();
  const posthog = usePostHog();
  const user = useUserData();

  const [savedExploratory, setSavedExploratory] = useLocalStorage<Datapoints>(
    "exploratory",
    exploratoryMap.home_value.code,
    { raw: true },
  );

  const defaultFilters = [
    { label: "Population", val: [] },
    { label: "Home Value", val: [] },
    { label: "Home Value Growth (YoY)", val: [] },
    { label: "Overvalued %", val: [] },
    { label: "Sale Inventory Growth (YoY)", val: [] },
    { label: "Median Household Income", val: [] },
  ].filter((filter) => isFilterExcludedFromGeo(filter, geo));

  const [exploratory, setExploratory] = useState<Datapoints>(
    savedExploratory ? savedExploratory : exploratoryMap.home_value.code,
  );
  const [filterList, setFilterList] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState("");
  const [graphQuery, setGraphQuery] = useState(true);
  const [filterPresets, setFilterPresets] =
    useState<IFilterPreset[]>(filterPresetsOptions);
  const [selectedFilters, setSelectedFilters] =
    useState<IFilter[]>(defaultFilters);

  const fetchFilterStates = (
    filters: Partial<IFilter>[],
    datapointValues: CurrentDataPoints[],
  ): FilterStates => {
    const filterState = {} as FilterStates;

    if (!!datapointValues?.length && !!filters?.length) {
      filters.forEach((filter) => {
        const exploratory = getCodeForLabel(filter.label!) as string;

        const states = datapointValues.filter((row) =>
          checkNumberValidity(row[exploratory]),
        );

        const min =
          minBy(states, (value) => value[exploratory])?.[exploratory] || 0;
        const max =
          maxBy(states, (value) => value[exploratory])?.[exploratory] || 0;

        filterState[exploratory] = {
          minVal: Number(min.toFixed(1)),
          maxVal: Number(max.toFixed(1)),
        };
      });
    }

    return filterState;
  };

  const updatePresets = (
    datapointValues: (CurrentDataPoints | FeatureState)[],
  ) => {
    let updatedPresets = [] as IFilterPreset[];

    const states = datapointValues.filter((row) =>
      checkNumberValidity(row[exploratory]),
    );

    setFilterPresets((prev) => {
      updatedPresets = prev.map((preset) => {
        const filters = preset.filters.map((filter: IFilter) => {
          const isAvailable = allExploratoryOptions.some(
            (exploratory) => exploratory.value === filter.code,
          );

          if (isAvailable && filter.code && !!filter.percentile?.length) {
            const val = filterOutliers(states, filter.code, filter.percentile);

            return { ...filter, val };
          }
          return filter;
        });

        return { ...preset, filters };
      });

      return updatedPresets;
    });

    return updatedPresets;
  };

  const setFiltersFromPreset = (preset: IFilterPreset) => {
    posthog.capture("Preset Button Click", {
      preset_type: preset.label,
    });
    if (!user.isPremiumOrBasic) {
      setPlanPopupTrigger("Set Filters From Preset");
      setShowPlanModal(true);
      return;
    }

    setSelectedPreset(preset.label);
    setSelectedFilters([
      ...cloneObject<IFilter[]>(preset.filters).filter((filter) =>
        isFilterExcludedFromGeo(filter, geo),
      ),
      ...selectedFilters.filter((obj) => obj.label === "state_code"),
    ]);
  };

  const handleExploratoryChange = (value: Datapoints, row: any) => {
    if (row) {
      const hasAccess = hasAccessToPremiumArea(user, row, value, geo);

      if (!hasAccess) {
        return false;
      }
    }

    setExploratory(value);
    setSavedExploratory(value);

    return true;
  };

  const resetFilters = () => {
    setSelectedPreset("");
    setSelectedFilters(defaultFilters);

    return defaultFilters;
  };

  return (
    <FilterContext.Provider
      value={{
        filterList,
        exploratory,
        filterPresets,
        selectedPreset,
        selectedFilters,
        graphQuery,
        setGraphQuery,
        resetFilters,
        setFilterList,
        updatePresets,
        setExploratory,
        setFilterPresets,
        setSelectedPreset,
        fetchFilterStates,
        setSelectedFilters,
        setFiltersFromPreset,
        handleExploratoryChange,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

const useFilters = () => {
  const values = useContext(FilterContext);

  if (!values) {
    throw new Error("Cannot use PresetContext outside of provider");
  }

  return values;
};

export default useFilters;
